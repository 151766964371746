import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import {HomePage, HomePageEn} from "./components/HomePage";
import {Page404} from "./components/404";
import {RSVP, RSVPEn} from "./components/RSVP";
import ReactGA from 'react-ga';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {Photos} from "./components/Photos";
ReactGA.initialize('UA-40974553-6');
ReactGA.pageview(window.location.pathname + window.location.search);

Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Photos} exact />
        <Route path="/home" component={HomePage} exact />
        <Route path="/rsvp" component={RSVP} />
        <Route path="/english" component={HomePageEn} exact />
        <Route path="/english/rsvp" component={RSVPEn} />
        <Route component={Page404} />
      </Switch>
    </Router>
  );
}

export default App;
