import moment from "moment/moment";

export function Countdown(props = { locale: "pl" }) {
    const { locale } = props;
    const daysLeft = moment('2022-06-25').diff(moment().startOf('day'), 'days');

    if (locale === "pl") {
        if (daysLeft === 0) {
            return <> (dzisiaj)</>
        }

        if (daysLeft === 1) {
            return <> (jutro)</>
        }

        if (daysLeft === 2) {
            return <> (pojutrze)</>
        }

        if (daysLeft > 2) {
            return <> (za {daysLeft} dni)</>
        }
    } else {
        if (daysLeft === 0) {
            return <> (today)</>
        }

        if (daysLeft === 1) {
            return <> (tomorrow)</>
        }

        if (daysLeft > 1) {
            return <> (in {daysLeft} days)</>
        }
    }

    return <></>;
}
