import { NavLink } from 'react-router-dom';
import {Button, Segment} from 'semantic-ui-react';

export function Page404() {
    return (
        <div className="ui container">
            <Segment>
                <h3 style={{color: "black"}}>Nie znaleziono takiej strony.</h3>
                <p>
                    <NavLink to="/">
                        <Button color="blue">Wróć do strony głównej</Button>
                    </NavLink>
                </p>
            </Segment>
        </div>
    );
}
