import {Button, Dimmer, Icon, Loader, Message, Segment} from 'semantic-ui-react';
import { Formik } from 'formik';
import {Form, Input, SubmitButton, Radio, TextArea} from 'formik-semantic-ui-react';
import { NavLink } from 'react-router-dom';
import {useState} from "react";
import { API } from 'aws-amplify';

interface IFormValues {
    name: string;
    confirmation: boolean | number;
    additionalGuests: string[];
    email: string;
    phone: string;
    comment: string;
    submitDate?: Date;
}

export function RSVP() {
    const [isSubmitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const initialValues: IFormValues = {
        name: '',
        confirmation: 1,
        additionalGuests: [],
        email: '',
        phone: '',
        comment: '',
    };

    const addPerson = (values: { additionalGuests: string[] }, setValues: (v: any) => void) => {
        const additionalGuests = [...values.additionalGuests];
        additionalGuests.push('');
        setValues({
            ...values,
            additionalGuests
        });
    };

    const removeAdditionalGuest = (idx: number, values: { additionalGuests: string[] }, setValues: (v: any) => void) => {
        const additionalGuests = [...values.additionalGuests];
        additionalGuests.splice(idx, 1);
        setValues({
            ...values,
            additionalGuests,
        });
    };

    const submitForm = async (d: IFormValues) => {
        setLoading(true);

        const apiName = 'api204a3885';
        const path = '/rsvp';
        const myInit = {
            body: {
                id: getRowId(),
                name: d.name,
                confirmation: Boolean(d.confirmation),
                additionalGuests: d.additionalGuests,
                email: d.email,
                phone: d.phone,
                comment: d.comment,
                submitDate: new Date(),
            },
            headers: {},
        };

        try {
            await API.post(apiName, path, myInit);
            setLoading(false);
            setSubmitted(true);
            setError('');
        } catch (e: unknown) {
            setLoading(false);
            setSubmitted(false);
            console.error(e);
            if (typeof e === 'string') {
                setError(e);
            } else if(e instanceof Error) {
                setError(e?.message || e.toString());
            } else {
                setError('Unknown error, sorry!');
            }
        }
    };

    return (
        <div className="ui container rsvp">
            <Dimmer active={isLoading}>
                <Loader size="large">Wysyłanie...</Loader>
            </Dimmer>
            <NavLink to="/">Powrót do strony głównej</NavLink>
            <h1>RSVP</h1>
            {error ? (
                <Message negative>
                    <Message.Header>Bardzo przepraszamy, wystąpił błąd podczas wysyłania formularza :(</Message.Header>
                    <p>Bardzo proszę o informację na maila <a href="mailto:jan@kondratowicz.pl">jan@kondratowicz.pl</a>, naprawię to najszybciej jak będę umiał.</p>
                </Message>
            ) : ''}
            {isSubmitted ? (
                <>
                    <p>Bardzo dziękujemy za potwierdzenie!</p>
                    <p>Jeżeli zajdzie taka potrzeba, zawsze możecie wrócić na tę stronę i&nbsp;wysłać formularz ponownie.</p>
                </>
            ) : (
                <>
                    <p>Będziemy wdzięczni, jeżeli skorzystacie z poniższego formularza, aby potwierdzić swoją obecność najpóźniej do końca marca.<br></br>Przypominamy, że ślub odbędzie się 25 czerwca :)</p>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={submitForm}
                    >
                    {({ errors, values, touched, setValues }) => (
                        <Form size="large">
                            <Segment>
                                <Input
                                    name="name"
                                    label="Imię i nazwisko:"
                                />
                                <Radio name="confirmation" label="Potwierdzam obecność!" value={1} />
                                <Radio name="confirmation" label="Nie będzie mnie..." value={0} />
                            </Segment>
                            {values.confirmation ? (
                            <Segment>
                                <h4>Planujesz przybyć z osobą towarzyszącą (lub większą grupą)? Prosimy o&nbsp;potwierdzenie poniżej.</h4>
                                {
                                    values.additionalGuests.length === 0 ? '' : values.additionalGuests.map((v, idx) => {
                                        return (
                                            <>
                                                <Input
                                                    name={`additionalGuests.${idx}`}
                                                    label={"Imię i nazwisko osoby towarzyszącej nr " + (idx + 1)}
                                                    key={idx}
                                                    action={{
                                                        color: 'red',
                                                        basic: true,
                                                        labelPosition: 'right',
                                                        icon: 'delete',
                                                        content: 'usuń',
                                                        onClick: (e: React.MouseEvent<HTMLElement>) => { e.preventDefault(); removeAdditionalGuest(idx, values, setValues); },
                                                    }}
                                                    actionPosition='right'
                                                />
                                            </>
                                        );
                                    })
                                }
                                <Button basic color="green" onClick={(e) => { e.preventDefault(); addPerson(values, setValues) }}>
                                    <Icon name="add user" /> Dodaj osobę towarzyszącą
                                </Button>
                            </Segment>
                            ) : '' }
                            <Segment>
                                <h4>Będziemy wdzięczni za pozostawienie aktualnych danych kontaktowych, żebyśmy mogli dać Wam znać w razie nieoczekiwanych zmian itp.</h4>
                                <Input
                                    name="email"
                                    label="E-mail:"
                                />
                                <Input
                                    name="phone"
                                    label="Numer telefonu:"
                                />
                            </Segment>
                            <Segment>
                                <TextArea name="comment" label="Miejsce na komentarze / uwagi / skargi / wnioski:" />
                                <SubmitButton primary size="large">
                                    Wyślij formularz
                                </SubmitButton>
                            </Segment>
                        </Form>
                    )}
                    </Formik>
                </>
            )}
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
}

function getRowId() {
    let ts = new Date().getTime() - 1300000000000;
    const randid = Math.floor(Math.random() * 512);
    ts = (ts * 64);   // bit-shift << 6
    ts = ts + 1;
    return (ts * 512) + randid;
}

export function RSVPEn() {
    const [isSubmitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const initialValues: IFormValues = {
        name: '',
        confirmation: 1,
        additionalGuests: [],
        email: '',
        phone: '',
        comment: '',
    };

    const addPerson = (values: { additionalGuests: string[] }, setValues: (v: any) => void) => {
        const additionalGuests = [...values.additionalGuests];
        additionalGuests.push('');
        setValues({
            ...values,
            additionalGuests
        });
    };

    const removeAdditionalGuest = (idx: number, values: { additionalGuests: string[] }, setValues: (v: any) => void) => {
        const additionalGuests = [...values.additionalGuests];
        additionalGuests.splice(idx, 1);
        setValues({
            ...values,
            additionalGuests,
        });
    };

    const submitForm = async (d: IFormValues) => {
        setLoading(true);

        const apiName = 'api204a3885';
        const path = '/rsvp';
        const myInit = {
            body: {
                id: getRowId(),
                name: d.name,
                confirmation: Boolean(d.confirmation),
                additionalGuests: d.additionalGuests,
                email: d.email,
                phone: d.phone,
                comment: d.comment,
                submitDate: new Date(),
            },
            headers: {},
        };

        try {
            await API.post(apiName, path, myInit);
            setLoading(false);
            setSubmitted(true);
            setError('');
        } catch (e: unknown) {
            setLoading(false);
            setSubmitted(false);
            console.error(e);
            if (typeof e === 'string') {
                setError(e);
            } else if(e instanceof Error) {
                setError(e?.message || e.toString());
            } else {
                setError('Unknown error, sorry!');
            }
        }
    };

    return (
        <div className="ui container rsvp">
            <Dimmer active={isLoading}>
                <Loader size="large">Sending...</Loader>
            </Dimmer>
            <NavLink to="/english">Back to the home page</NavLink>
            <h1>RSVP</h1>
            {error ? (
                <Message negative>
                    <Message.Header>We're really sorry, there was a problem sending the form:(</Message.Header>
                    <p>Please contact me at <a href="mailto:jan@kondratowicz.pl">jan@kondratowicz.pl</a>, I'll fix it as soon as I can.</p>
                </Message>
            ) : ''}
            {isSubmitted ? (
                <>
                    <p>Thank you for confirming!</p>
                    <p>In case you want to add or amend something, just feel free to come back to this page and send the form again.</p>
                </>
            ) : (
                <>
                    <p>We'd appreciate it if you used the form below to confirm your attendance till the end of march. Just a reminder, the wedding is on June 25th :)</p>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={submitForm}
                    >
                        {({ errors, values, touched, setValues }) => (
                            <Form size="large">
                                <Segment>
                                    <Input
                                        name="name"
                                        label="Full name:"
                                    />
                                    <Radio name="confirmation" label="I'll be there!" value={1} />
                                    <Radio name="confirmation" label="I can't make it......" value={0} />
                                </Segment>
                                {values.confirmation ? (
                                    <Segment>
                                        <h4>Are you planning to come with a plus one or as part of a larger group? Please list everyone in your party below:</h4>
                                        {
                                            values.additionalGuests.length === 0 ? '' : values.additionalGuests.map((v, idx) => {
                                                return (
                                                    <>
                                                        <Input
                                                            name={`additionalGuests.${idx}`}
                                                            label={"Full name of your guest #" + (idx + 1)}
                                                            key={idx}
                                                            action={{
                                                                color: 'red',
                                                                basic: true,
                                                                labelPosition: 'right',
                                                                icon: 'delete',
                                                                content: 'remove',
                                                                onClick: (e: React.MouseEvent<HTMLElement>) => { e.preventDefault(); removeAdditionalGuest(idx, values, setValues); },
                                                            }}
                                                            actionPosition='right'
                                                        />
                                                    </>
                                                );
                                            })
                                        }
                                        <Button basic color="green" onClick={(e) => { e.preventDefault(); addPerson(values, setValues) }}>
                                            <Icon name="add user" /> Add another guest
                                        </Button>
                                    </Segment>
                                ) : '' }
                                <Segment>
                                    <h4>We'd really appreciate it if you left your contact information, so we can contact you in case of some unforeseen changes etc.</h4>
                                    <Input
                                        name="email"
                                        label="E-mail:"
                                    />
                                    <Input
                                        name="phone"
                                        label="Phone number:"
                                    />
                                </Segment>
                                <Segment>
                                    <TextArea name="comment" label="Comments / notes / questions / grievances:" />
                                    <SubmitButton primary size="large">
                                        Submit the form
                                    </SubmitButton>
                                </Segment>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
}