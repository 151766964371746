import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import {Countdown} from "./Countdown";
import {Button} from "semantic-ui-react";
import {NavLink} from 'react-router-dom';
import {LatLng} from "leaflet";
import horizontalImg from "../assets/photos/horizontal2.jpg";
import doveImg from "../assets/photos/dove.png";

const position = new LatLng(52.194285354682236, 20.996716261282256);
export const HomePage = () => (
    <div className="main">
        <div className="top_part">
            <div id="header">
                <div id="top_name">
                    <p>Maria + Jan</p>
                </div>
                <div>
                    <p>Sobota 25 czerwca 2022 roku<Countdown locale="pl" />.</p>
                </div>
            </div>
            <div className="text-overlay">

                <h1>Maria + Jan</h1>
                <img id="dove" src={doveImg} alt="M+J=♥"/>
                <div id="kochani">

                    <p>Kochani!<br/><br/>Z wielką radością zapraszamy Was na nasz ślub, który odbędzie się w
                        sobotę 25 czerwca 2022 roku o godzinie 18:00.</p>
                    <p className="pull-right">Maria Dorn i Jan Kondratowicz</p>
                </div>
            </div>
        </div>
        <section className="photo-overlayA">
            <div className="img-backdropA">
            </div>
        </section>
        <div id="info" className="ui segment vertical">
            <div className="ui container text splash">
                <h3>Lokalizacja</h3>
                <p>Ślub oraz wesele odbędą się w lokalu Mokoto w warszawskim Forcie Mokotów.</p>
                <p id="address" className="center">
                    Mokoto Events<br/>
                    ul. Racławicka 99<br/>
                    02-634 Warszawa
                </p>
                <p>
                    Wjazd przez szlaban, w weekend parkowanie jest darmowe. <a href="https://g.page/Mokoto-Events?share"
                                                                               id="maps_link">Link do Google Maps</a>.
                </p>
                <p>Będziemy wdzięczni, jeżeli potwierdzicie swoją obecność korzystając z&nbsp;formularza RSVP:</p>
                <p className="center">
                    <NavLink to="/rsvp">
                        <Button color="green" size="massive">RSVP</Button>
                    </NavLink>
                </p>
            </div>

        </div>
        <div className="stripe">
            <div className="container">
                <div className="row">
                    <div className="center aligned column map-container">
                        <MapContainer center={position} zoom={16} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position}>
                                <Popup>
                                    Mokoto, Fort Mokotów<br/>
                                    Racławicka 99<br/>
                                    02-634 Warszawa
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
        <div id="info" className="ui segment vertical">
            <div className="ui container text splash">
                <h3>Dodatkowe informacje</h3>
                <p>
                    🎁 Najbardziej ucieszy nas Wasza obecność. Gdybyście jednak chcieli nam coś podarować, będzie nam
                    bardzo miło, jeśli dorzucicie się do naszego funduszu na podróż poślubną.
                </p>
                <p>
                    💐 Zgodnie z tradycją goście obsypują młodą parę bukietami - ponieważ nie jesteśmy fanami kwiatów,
                    ale bardzo kochamy pieski, możecie środki zarezerwowane na kwiatki wpłacić na rzecz jednej z poniższych fundacji:
                    <ul>
                        <li><a href="https://fundacjajudyta.com" target="_blank">Fundacja dla Szczeniąt Judyta</a></li>
                        <li><a href="http://www.psianiol.org.pl/" target="_blank">Fundacja Azylu pod Psim Aniołem</a></li>
                        <li><a href="https://viva.org.pl/" target="_blank">Fundacja Viva!</a></li>
                    </ul>
                </p>
                <p>
                    W razie jakichkolwiek pytań lub wątpliwości kontaktujcie się bezpośrednio z&nbsp;nami, ewentualnie
                    z&nbsp;naszymi wspaniałymi świadkami - Heleną Łygas lub Duszanem Almonkari.
                </p>
                <p>
                    <br></br>
                    Maria: <a href="tel:+48787350289">+48 787 350 289</a>
                </p>
                <p>
                    Jan: <a href="tel:+48660261561">+48 660 261 561</a>
                </p>
            </div>
        </div>
        <div className="photo-overlayB">

            <img id="spacer" src={horizontalImg} alt="M+J=♥" />

        </div>
        <section id="bottom" className="bottom">
            <img id="doveS" src={doveImg} alt="M+J=♥" />

        </section>
    </div>
);


export const HomePageEn = () => (
    <div className="main">
        <div className="top_part">
            <div id="header">
                <div id="top_name">
                    <p>Maria + Jan</p>
                </div>
                <div>
                    <p>Saturday, June 25th 2022<Countdown locale="en" />.</p>
                </div>
            </div>
            <div className="text-overlay">

                <h1>Maria + Jan</h1>
                <img id="dove" src={doveImg} alt="M+J=♥"/>
                <div id="kochani">

                    <p>Welcome!<br/><br/>With great pleasure we would like to invite you to our wedding, which will take place on Saturday, June 25th at 6 PM.</p>
                    <p className="pull-right">Maria Dorn and Jan Kondratowicz</p>
                </div>
            </div>
        </div>
        <section className="photo-overlayA">
            <div className="img-backdropA">
            </div>
        </section>
        <div id="info" className="ui segment vertical">
            <div className="ui container text splash">
                <h3>Location</h3>
                <p>The wedding and reception will take place at a venue called Mokoto located in Warsaw's Fort Mokotów.</p>
                <p id="address" className="center">
                    Mokoto Events<br/>
                    ul. Racławicka 99<br/>
                    02-634 Warszawa
                </p>
                <p>
                    <a href="https://g.page/Mokoto-Events?share" id="maps_link">Link to Google Maps</a>.
                </p>
                <p>We'd appreciate it if you could confirm your attence using the RSVP form below:</p>
                <p className="center">
                    <NavLink to="/english/rsvp">
                        <Button color="green" size="massive">RSVP</Button>
                    </NavLink>
                </p>
            </div>

        </div>
        <div className="stripe">
            <div className="container">
                <div className="row">
                    <div className="center aligned column map-container">
                        <MapContainer center={position} zoom={16} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position}>
                                <Popup>
                                    Mokoto, Fort Mokotów<br/>
                                    Racławicka 99<br/>
                                    02-634 Warszawa
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
        <div id="info" className="ui segment vertical">
            <div className="ui container text splash">
                <h3>Additional information</h3>
                <p>
                    🎁 Having you as guests is all we're asking for. However, if you're thinking about a gift, we'd be really happy if you just supplemented our honeymoon travel budget.
                </p>
                <p>
                    💐 Traditionally guests bring flowers for the the newlyweds. However, we're not huge fans flowers, but we absolutely love dogs, so instead of buying a bouquet, please consider donating to a charity that helps animals. Below are a few charities like that in Poland:
                    <ul>
                        <li><a href="https://fundacjajudyta.com" target="_blank">Fundacja dla Szczeniąt Judyta</a></li>
                        <li><a href="http://www.psianiol.org.pl/" target="_blank">Fundacja Azylu pod Psim Aniołem</a></li>
                        <li><a href="https://viva.org.pl/" target="_blank">Fundacja Viva!</a></li>
                    </ul>
                </p>
                <p>
                    In case of any questions please don't hesitate to contact us directly, or reach out to our lovely witnesses - Helena Łygas or Duszan Almonkari.
                </p>
                <p>
                    <br></br>
                    Maria: <a href="tel:+48787350289">+48 787 350 289</a>
                </p>
                <p>
                    Jan: <a href="tel:+48660261561">+48 660 261 561</a>
                </p>
            </div>
        </div>
        <div className="photo-overlayB">

            <img id="spacer" src={horizontalImg} alt="M+J=♥" />

        </div>
        <section id="bottom" className="bottom">
            <img id="doveS" src={doveImg} alt="M+J=♥" />

        </section>
    </div>
);
