import {Gallery, Item} from 'react-photoswipe-gallery'
import doveImg from "../assets/photos/dove.png";
import 'photoswipe/dist/photoswipe.css'
import {imageList} from "../assets/imagelist";

const s3Prefix = "https://mariajan.s3.eu-central-1.amazonaws.com/";

export const Photos = () => (
    <div className="main">
        <div className="ui segment vertical">
            <div className="ui container-fluid gallery">
                <p>Autorem wszystkich zdjęć jest <a href="http://dominikcudny.com" target="_blank">Dominik Cudny</a>.</p>
                <Gallery withDownloadButton>

                    {imageList.map((img) => (<Item
                        original={s3Prefix + img[0]}
                        thumbnail={s3Prefix + "th" + img[0]}
                        width={img[1]}
                        height={img[2]}
                        key={img[0]}
                    >
                        {({ref, open}) => (
                            // @ts-ignore
                            <img ref={ref} onClick={open} src={s3Prefix + "th" + img[0]}/>
                        )}
                    </Item>))}

                </Gallery>


            </div>

        </div>
        <section id="bottom" className="bottom">
            <img id="doveS" src={doveImg} alt="M+J=♥"/>

        </section>
    </div>
);
